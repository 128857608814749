import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CoretanMentorDisukai from "../../components/Dashboard/CoretanMentorDisukai"

const DashboardCoretanPage = () => {
  return (
    <Layout>
      <SEO title="Coretan mentor disukai" />
      <CoretanMentorDisukai />
    </Layout>
  )
}

export default DashboardCoretanPage
