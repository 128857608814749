import React from "react"

import { connect } from "react-redux"

import DashboardComponent from ".."

import * as sessionSelectors from "../../../redux/slices/session/selectors"

import { Styles } from "./style"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

function CoretanMentorDisukai(props) {
  return (
    <DashboardComponent chosen={3}>
      <Styles className="layout">
        <div style={{ color: "gray", textAlign: "center" }}>Coming Soon</div>
      </Styles>
    </DashboardComponent>
  )
}

export default withConnect(CoretanMentorDisukai)
